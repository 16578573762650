/*
 * This fixes an issue with icons misalignment.
 * Problem with misalignment comes up with Buefy update 0.8.x
 */

.button, nav.navbar, .card-header-title, .card-footer-item {
  .icon {
    i {
      height: inherit;
    }
  }
}

.icon i {
  &.mdi-24px {
    height: auto!important;
  }
}

@include touch {
  .navbar-item:not(.has-dropdown), .navbar-link {
    display: flex;
  }
}

aside.aside {
  .menu-list {
    li a.has-icon {
      display: flex;

      .icon {
        i {
          height: auto;
        }
      }

      .menu-item-label {
        line-height: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}

