@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap');

@import "~bulma/sass/utilities/_all.sass";
$family-primary: "Nunito",
sans-serif;

@import "~bulma/sass/base/_all.sass";
@import "~bulma/sass/grid/_all.sass";
@import "~bulma/sass/elements/_all.sass";
@import "~bulma/sass/form/_all.sass";
@import "~bulma/sass/components/_all.sass";
@import "~bulma/sass/layout/_all.sass";
@import "~@fortawesome/fontawesome-free/css/all.css";

@import "theme-default";
@import "mixins";
@import "nav-bar";
@import "aside";
@import "title-bar";
@import "hero-bar";
@import "card";
@import "table";
@import "tiles";
@import "form";
@import "main-section";
@import "modal";
@import "footer";
@import "misc";
@import "icon-fix";

@import '~bulma-tooltip';
@import '~bulma-helpers/css/bulma-helpers.min.css';
@import '~react-toastify/dist/ReactToastify.css';


// html,
// body {
//   overflow: hidden;
// }

html,
body,
#root {
  height: 100%;
  overflow: auto;
}

// .card {
//   margin-bottom: 1rem;
// }

.element {
  &.is-loading {
    position: relative;
    pointer-events: none;
    opacity: 0.5;

    &:after {
      @include loader;
      position: absolute;
      top: calc(50% - 2.5em);
      left: calc(50% - 2.5em);
      width: 5em;
      height: 5em;
      border-width: 0.25em;
    }
  }
}

.slick-track {
  display: flex !important;
  align-items: flex-end;
}


 /* Hide Sign Up Fields */
 .amplify-textfield, .amplify-divider, button[type="submit"].amplify-button, .amplify-button--link {
   display: none !important;
 }


// .slick-track .slick-slide {
//   display: flex;
//   height: auto;
//   align-items: center;
//   justify-content: center;
// }