.is-user-avatar {
  &.has-max-width {
    max-width: $size-base * 7;
  }

  &.is-aligned-center {
    margin: 0 auto;
  }

  img {
    margin: 0 auto;
    border-radius: $radius-rounded;
  }
}

.icon.has-update-mark {
  position: relative;

  &:after {
    content: '';
    width: $icon-update-mark-size;
    height: $icon-update-mark-size;
    position: absolute;
    top: 1px;
    right: 1px;
    background-color: $icon-update-mark-color;
    border-radius: $radius-rounded;
  }
}
