table.table {
  thead {
    th {
      border-bottom-width: 1px;
    }
  }

  td {
    .image {
      width: $table-avatar-size;
      height: $table-avatar-size;
    }

    &.is-progress-col {
      min-width: 5rem;
      vertical-align: middle;
    }
  }

  tr {
    td.is-image-cell:first-child {
      .image {
        margin: 0 auto;
      }
    }
  }
}

.b-table {
  .table {
    border: 0;
    border-radius: 0;
  }

  /* This stylizes buefy's pagination */
  .table-wrapper {
    margin-bottom: 0;
  }

  .table-wrapper + .level {
    padding: $notification-padding;
    padding-left: $card-content-padding;
    padding-right: $card-content-padding;
    margin: 0;
    border-top: $base-color-light;
    background: $notification-background-color;

    .pagination-link {
      background: $button-background-color;
      color: $button-color;
      border-color: $button-border-color;

      &.is-current {
        border-color: $button-active-border-color;
      }
    }

    .pagination-previous, .pagination-next, .pagination-link {
      border-color: $button-border-color;
      color: $base-color;

      &[disabled] {
        background-color: transparent;
      }
    }
  }
}

@include mobile {
  .card {
    &.has-table {
      .b-table {
        .table-wrapper + .level {
          .level-left + .level-right {
            margin-top: 0;
          }
        }
      }
    }
    &.has-mobile-sort-spaced {
      .b-table {
        .field.table-mobile-sort {
          padding-top: $default-padding * .5;
        }
      }
    }
  }
  .b-table {
    .field.table-mobile-sort {
      padding: 0 $default-padding * .5;
    }

    .table.has-mobile-cards {
      tr {
        box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
        margin-bottom: 3px!important;
      }
      td {
        &.is-progress-col {
          span, progress {
            display: flex;
            width: 45%;
            align-items: center;
            align-self: center;
          }
        }

        &.checkbox-cell, &.is-image-cell {
          border-bottom: 0!important;
        }

        &.checkbox-cell, &.is-actions-cell {
          &:before {
            display: none;
          }
        }

        &.has-no-head-mobile {
          &:before {
            display: none;
          }

          span {
            display: block;
            width: 100%;
          }

          &.is-progress-col {
            progress {
              width: 100%;
            }
          }

          &.is-image-cell {
            .image {
              width: $table-avatar-size-mobile;
              height: auto;
              margin: 0 auto $default-padding * .25;
            }
          }
        }
      }
    }
  }
}
