@include desktop {
  html {
    &.has-aside-left {
      &.has-aside-expanded {
        nav.navbar, body {
          padding-left: $aside-width;
        }
      }
      nav.navbar, body {
        @include transition(padding-left);
      }
      aside.is-placed-left {
        display: block;
      }
    }
  }

  aside.aside.is-expanded {
    width: $aside-width;

    .menu-list {
      @include icon-with-update-mark($aside-icon-width);

      span.menu-item-label {
        display: inline-block;
      }

      li.is-active {
        ul {
          display: block;
        }
      }
    }
  }
}

aside.aside {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  height: 100vh;
  padding: 0;
  box-shadow: $aside-box-shadow;
  background: $aside-background-color;

  .aside-tools {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: $aside-tools-background-color;
    color: $aside-tools-color;
    line-height: $navbar-height;
    height: $navbar-height;
    padding-left: $default-padding * .5;
    flex: 1;

    .icon {
      margin-right: $default-padding * .5;
    }
  }

  .menu-list {
    li {
      a {
        &.has-dropdown-icon {
          position: relative;
          padding-right: $aside-icon-width;

          .dropdown-icon {
            position: absolute;
            top: $size-base * .5;
            right: 0;
          }
        }
      }
      ul {
        display: none;
        border-left: 0;
        background-color: darken($base-color, 2.5%);
        padding-left: 0;
        margin: 0 0 $default-padding * .5;

        li {
          a {
            padding: $default-padding * .5 0 $default-padding * .5 $default-padding * .5;
            font-size: $aside-submenu-font-size;

            &.has-icon {
              padding-left: 0;
            }
            &.is-active {
              &:not(:hover) {
                background: transparent;
              }
            }
          }
        }
      }
    }
  }

  .menu-label {
    padding: 0 $default-padding * .5;
    margin-top: $default-padding * .5;
    margin-bottom: $default-padding * .5;
  }

}

@include touch {
  #root, nav.navbar {
    @include transition(margin-left);
  }
  aside.aside {
    @include transition(left);
  }
  html.has-aside-mobile-transition {
    body {
      overflow-x: hidden;
    }
    body, #root, nav.navbar {
      width: 100vw;
    }
    aside.aside {
      width: $aside-mobile-width;
      display: block;
      left: $aside-mobile-width * -1;

      .image {
        img {
          max-width: $aside-mobile-width * .33;
        }
      }

      .menu-list {
        li.is-active {
          ul {
            display: block;
          }
        }
        a {
          @include icon-with-update-mark($aside-icon-width);

          span.menu-item-label {
            display: inline-block;
          }
        }
      }
    }
  }
  html.has-aside-mobile-expanded {
    #root, nav.navbar {
      margin-left: $aside-mobile-width;
    }
    aside.aside {
      left: 0;
    }
  }
}
