.field {
  &.has-check {
    .field-body {
      margin-top: $default-padding * .125;
    }
  }
  .control {
    .mdi-24px.mdi-set, .mdi-24px.mdi:before {
      font-size: inherit;
    }
  }
}
.upload {
  .upload-draggable {
    display: block;
  }
}

.input, .textarea, select {
  box-shadow: none;

  &:focus, &:active {
    box-shadow: none!important;
  }
}

.switch, .b-checkbox.checkbox {
  input[type=checkbox] {
    &:focus + .check, &:focus:checked + .check {
      box-shadow: none!important;
    }
  }
}
